import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import classNames from 'classnames'
import moment from "moment";
import { Tooltip } from 'antd'
import style from './style.module.scss'
import projectConfig  from '../../../config'
import packageJson from '../../../../../../package.json'

const mapStateToProps = ({ settings }) => ({ settings })

const getBuildDate = (epoch) => {
  const buildDate = moment(epoch).format("DD-MMM-YYYY HH:mm");
  return buildDate;
};

// const Footer = ({ settings: { isContentMaxWidth, logo, description } }) => {
const Footer = ({ settings: { isContentMaxWidth } }) => {
  return (
    <div
      className={classNames(style.footer, {
        [style.footerFullWidth]: !isContentMaxWidth,
      })}
    >
      <div className={style.inner}>
        <div className="row">
          <div className="col-md-8">
            <p>
              <strong>{ projectConfig.appFullName }</strong>
            </p>
            <p>
              { projectConfig.appDesc }
            </p>
            <a
              href="https://weget.co.th"
              target="_blank"
              rel="noopener noreferrer"
              className={style.sellpixels}
            >
              { projectConfig.appFullName }
              <span />
            </a>
            <br />
            <p className="mb-0">
              { projectConfig.appCopyright }
            </p>
            <p className="mb-0">
              <a href="/#/web/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Policy 
              </a> | <a href="https://www.weget.co.th" target="_blank" rel="noopener noreferrer">Developed By WEGET</a>
              <br />
              <Tooltip placement="top" title={getBuildDate(packageJson.buildDate)} >
                Build No {packageJson.buildDate} {getBuildDate(packageJson.buildDate)} 
              </Tooltip>
            </p>
          </div>
          <div className="col-md-4">
            <div style={{marginTop:"15px", marginLeft:"15px", float: "right"}}>
              <Link to={`/web/home`}>
                {projectConfig.appLogoWidth || projectConfig.appLogo}
              </Link>
            </div>
            {projectConfig.appLogoWidth ? <div style={{clear: 'both'}}></div> : ''}
            {
              Object.keys(projectConfig.socialMedia).map(key => 
                <div key={key} style={{marginTop:"15px", float: "right"}}>
                  {projectConfig.socialMedia[key]}
                </div>
                // projectConfig.socialMedia[key]
              )
            }
            {/* <div className={style.logo}>
              <div>
                <img
                  src="app/bnithai/logo/android-chrome-512x512.png"
                  alt="logo"
                  style={{ maxWidth: '40px' }}
                />
              </div>
              <div className={style.logo__letter}>A</div>
              <div className={style.logo__name}>{logo}</div>
              <div className={style.logo__descr}>{description}</div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(Footer)
