import firebase from 'firebase/app'
import { notification } from 'antd'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
// import { userLog } from '../../config/dbUtils'

import firebaseConfig from './firebase-config'

// import firebaseConfig from './firebaseConfig_webg' // firebase use webg-wg
// import firebaseConfig from './firebaseConfig_bnithai' // firebase use bnithai
// import firebaseConfig from './firebaseConfig_shin-a' // firebase use shin-aa
// import firebaseConfig from './firebaseConfig_bni-integrity' // firebase use bni-integrity
// import firebaseConfig from './firebaseConfig_housed' // firebase use house-th
// import firebaseConfig from './firebaseConfig_gentlestots' // firebase use gentlestots
// import firebaseConfig from './firebaseConfig_salepage' // firebase use salepagethai
// import firebaseConfig from './firebaseConfig_getSmile' // firebase use getsmilethai
// import firebaseConfig from './firebaseConfig_sale-agent' // firebase use sale-agent
// import firebaseConfig from './firebaseConfig_virualDip' // firebase use virualonline
// import firebaseConfig from './firebaseConfig_ybkk' // firebase use ybkk-gold


// import firebaseConfig from './firebaseConfig_ballweb4' // firebase use ballweb4 // for uat test

// fb.database.ServerValue.TIMESTAMP
// fb.firestore.FieldValue.serverTimestamp()

firebase.initializeApp(firebaseConfig)
export const fb = firebase;
export const auth = firebase.auth()
export const db = firebase.firestore()
export const rdb = firebase.database()
export const storage = firebase.storage()
// export const functions = firebase.functions().region('asia-southeast2')
export const userLog = (log, optionData) => {
  const {uid, email, type, action, messages} = log
  const batch = db.batch()
  if(optionData){
    const {ref, data} = optionData
    batch.set(
      ref,
      data, 
      { merge: true}
    )
  }
  batch.set(
    db.collection("_userLog").doc(email),
    {
      _email: email, 
      _uid: uid || '',
      updateDate: fb.firestore.FieldValue.serverTimestamp(),
      [action || '_']: fb.firestore.FieldValue.increment(1),
    }, 
    { merge: true}
  )
  batch.set(
    db.collection("_userLog").doc(email).collection('log').doc(),
    {
      // _uid: uid,
      // _email: email, 
      createDate: fb.firestore.FieldValue.serverTimestamp(),
      type: type || '',
      action: action || '_', 
      messages: messages || ''
      // processResult: 'success', 
      // messages: 'get my user profile'
    }
  )
  batch.commit()
}

export async function login(email, password) {
  return auth
    .signInWithEmailAndPassword(email, password)
    .then((response) => {
      console.log("signed = ", response)
      userLog(
        {uid: response.user.uid, email: response.user.email, type: 'info', action: 'signIn'}, 
      )
      return true
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function register(email, password, firstName, lastName, mobile) {
  return registerWithRole(email, password, firstName, lastName, mobile, "member")
}
export async function registerWithRole(email, password, firstName, lastName, mobile, role) {
  return auth
    .createUserWithEmailAndPassword(email, password)
    .then(response => {
      if (response.user) {
        const { uid } = response.user
        // db.collection("user").doc(uid).set({
        // db.collection("user").add({
        //   uid: uid,
        //   role: role,
        //   roles: [role],
        //   firstName, 
        //   lastName, 
        //   mobile,
        //   email,
        //   createDate: fb.firestore.FieldValue.serverTimestamp(),
        //   createFrom: 'Sign Up   Form',
        // })

        db.collection("_stat_").doc(role).set(
          {
            register: fb.firestore.FieldValue.increment(1),
          }, 
          { merge: true}
        )

        userLog(
          {uid: response.user.uid, email: response.user.email, type: 'info', action: 'signUp'}, 
          {
            ref: db.collection("user").doc(),
            data: {
              uid: uid,
              role: role,
              roles: [role],
              firstName, 
              lastName, 
              mobile,
              email,
              createDate: fb.firestore.FieldValue.serverTimestamp(),
              // createFrom: 'Sign Up Form',
            }
          }
        )
      }
      return true
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}
export async function createUserAccount(user_id, email, password, firstName, lastName, mobile, role) {
  return auth
    .createUserWithEmailAndPassword(email, password)
    .then(response => {
      if (response.user) {
        const { uid } = response.user
        db.collection("user").doc(user_id).update({
          uid: uid,
          updateDate: fb.firestore.FieldValue.serverTimestamp(),
        })
      }
      return true
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function currentAccount() {
  let userLoaded = false
  function getCurrentUser(authUser) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(authUser.currentUser)
      }
      const unsubscribe = authUser.onAuthStateChanged(user => {
        userLoaded = true
        unsubscribe()
        const getUserData = async () => {
          if (user) {
            const snapshot = await firebase.firestore().collection('user').where("uid", "==", user.uid).get()
            const result = snapshot.docs.map(doc => doc.data())
            const resultkey = snapshot.docs.map(doc => doc.id)
            const key = result.length > 0 ? resultkey[0] : null
            const userFields = result.length > 0 ? result[0] : null
            if(process.env.NODE_ENV === 'development')
              console.log("current = ", userFields)
            // else

            const userAuthResult = snapshot.docs.map(doc => doc.data())
            const userAuth = userAuthResult.length > 0 ? userAuthResult[0] : null
            if(userFields){
              const mergedUser = Object.assign(user, {
                roles: userAuth.roles || '',
                // id: user.uid,
                // _uid: user.uid,
                id: key,
                firstName: userFields.firstName || '',
                lastName: userFields.lastName || '',
                nickName: userFields.nickName || '',
                mobile: userFields.mobile || '',
                role: userFields.role,
                // avatar: user.photoUrl,
                photo: userFields.photo,
                photoBackground: userFields.photoBackground,

                company: userFields.company,
                company_id: userFields.company_id,

                position: userFields.position,
                companyName: userFields.companyName,
                companyDesc: userFields.companyDesc,
                companyLogo: userFields.companyLogo,

                companyRole: userFields.companyRole,

                chapter: userFields.chapter,
                chapter_id: userFields.chapter_id,

                creditBalance: userFields.creditBalance,
                pointBalance: userFields.pointBalance,
              })
              // userLog(
              //   {uid: user.uid, email: user.email, type: 'info', action: 'currentAccount'}, 
              // )
              return mergedUser
            }
          }
          // console.log("added userActivityLog")
          // db.collection("userActivityLog").add({user: user.id, email: user.email, active: 'getProfile', messages: 'get my user profile'})
          return user
        }
        resolve(getUserData())
      }, reject)
    })
  }
  return getCurrentUser(auth)
}

export async function logout() {
  return auth.signOut().then(() => true)
}

export async function doGetUserProfile() {
  const docRef = db.collection("user").doc("c");
  docRef.get().then(function func(doc) {
      if (doc.exists) {
          console.log("Document data:", doc.data());
      } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
      }
  }).catch(function (error) {
      console.log("Error getting document:", error);
  });
}

// Auth ########################################################################

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
  auth.signInWithEmailAndPassword(email, password)

// Sign Out
export const doSignOut = () => auth.signOut()

// // Password Reset
export const doPasswordReset = email =>
  // console.log("email for reset = ",email)
  auth
    .sendPasswordResetEmail(email)
    .then(function(response) {
      // Password reset email sent.
      console.log("Reset email = ", email)
      userLog(
        {uid: response.user.uid, email: response.user.email, type: 'info', action: 'resetPassword'}, 
      )
      notification.info({
        message: "Reset Password",
        description: "Please check inbox / trash in your email for reset your password.",
      })
    })
    .catch(function(error) {
      // Error occurred. Inspect error.code.
      console.log("Error = ", error)
      notification.warning({
        message: error.code,
        description: error.message,
      })
    });

// Password Change
export const doPasswordUpdate = password => {
  if(password){
    fb.auth().onAuthStateChanged(function(user) {
      if(user){
        // auth.currentUser
        user.updatePassword(password)
        .then(function(response) {
          // Password reset email sent.
          // console.log("Reset email = ", email)
          userLog(
            {uid: response.user.uid, email: response.user.email, type: 'info', action: 'resetPassword', messages: password}, 
          )
          notification.info({
            message: "Change Password",
            description: "Change your password success.",
          })
        })
        .catch(function(error) {
          // Error occurred. Inspect error.code.
          console.log("error = ", error)
          notification.warning({
            message: error.code,
            description: error.message,
          })
        })
      } else {
        // No user is signed in.
      }
    })
  }
}
  
  

// //Email Update
// export const doEmailUpdate = email =>
//   auth.currentUser.updateEmail(email).then(
//     db
//       .collection("user")
//       .doc("user3") //retrieve user name from fb
//       .update({
//         email: email
//       })
//   );

// //Update nick name
// export const doNickNameUpdate = nickname =>
//   db
//     .collection("user")
//     .doc("c")
//     .update({
//       name: nickname
//     });

// export const doBioUpdate = newBio =>
//   db
//     .collection("user")
//     .doc(localStorage.getItem("username"))
//     .update({
//       bio: newBio
//     });
